import { graphql } from 'gatsby';
import * as React from 'react';
import AnnouncmentTile from '../components/announcement-tile.component';
import Carousel from '../components/carousel.component';
import Content from '../components/content.component';
import EventTile from '../components/event-tile.component';
import Layout from '../components/layout.component';

function IndexPage(props) {
  const { dataJson, allEventsJson, allAnnouncementsJson } = props.data;
  return (
    <Layout>
      <Carousel
        indexPage={dataJson.indexPage}
        coverText={`Welcome to ${dataJson.churchName}`}
      ></Carousel>
      <Content>
        <div className="lg:flex justify-between p-8">
          <div>
            <h2 className="text-4xl p-3">Upcoming Events</h2>
            {allEventsJson.nodes.map((event) => {
              return <EventTile event={event} key={event.id}></EventTile>;
            })}
          </div>
          <div>
            <h2 className="text-4xl p-3">Announcements</h2>
            {allAnnouncementsJson.nodes.map((announcement) => {
              return (
                <AnnouncmentTile
                  announcement={announcement}
                  key={announcement.id}
                ></AnnouncmentTile>
              );
            })}
          </div>
        </div>
      </Content>
    </Layout>
  );
}

export const query = graphql`
  query IndexQuery {
    dataJson {
      indexPage {
        images
      }
      churchName
    }
    allEventsJson {
      nodes {
        title
        blurb
        location
        id
        date
      }
    }
    allAnnouncementsJson {
      nodes {
        title
        blurb
        id
        date
      }
    }
  }
`;

export default IndexPage;
