import React from 'react';

const Content = ({ children }) => {
  return (
    <div className="min-h-full">
      <div className="ml-auto mr-auto lg:max-w-screen-lg bg-white">{children}</div>
    </div>
  );
};

export default Content;
