import React from 'react';

const EventTile = ({ event }) => {
  const date = new Date(event.date);
  return (
    <div className="flex shadow-md rounded-sm m-2 lg:max-w-md">
      <div className="p-3 bg-white">
        <h3 className="text-3xl text-gray-800 mb-2">{event.title}</h3>
        <p>{event.blurb}</p>
      </div>
      <div className="bg-blue-400 flex p-1">
        <div className="mt-auto mb-auto text-white">
          <h3 className="text-xl">{date.toLocaleDateString()}</h3>
          {date.toLocaleTimeString()}
        </div>
      </div>
    </div>
  );
};

export default EventTile;
