import React from 'react';

const AnnouncementTile = ({ announcement }) => {
  const date = new Date(announcement.date);
  return (
    <div className="flex shadow-md rounded-sm m-2 lg:max-w-md">
      <div className="p-3 bg-white">
        <h3 className="text-3xl text-gray-800 mb-2">{announcement.title}</h3>
        <p>{announcement.blurb}</p>
      </div>
      <h3 className="text-lg">{date.toLocaleDateString()}</h3>
    </div>
  );
};

export default AnnouncementTile;
